export const mechanics = {
    "Acting": "Games with the Acting mechanic require players to use some form of mime or mimicry to communicate with the other players.",
"Action Drafting": "Players select from an assortment of Actions in a shared pool. The available Actions are limited in quantity, and once a player has chosen an Action it may not be chosen again. NOTE: Worker Placement is a subset of this category, and games with that mechanism should be placed in that category instead.",
"Action Points": "A player receives a number of Action Points or Operation Points on their turn. They may spend them on a variety of Actions.",
"Action Queue": "Players create Action Queues and perform them in sequence. Queues can either be Batch queues, where all actions are executed in sequence, or Rolling queues where actions are added to the end of the Queue, and the first action is then executed. Players may each have their own queue of actions, or there may be a shared action queue.",
"Action Retrieval": "Each player has a set of Actions available to them, embodied in cards, tokens, or some other affordance. Once performed, they are spent and may not be performed again until retrieved. Action Retrieval typically is itself an Action, or may take an entire turn.",
"Action Timer": "Players place owned timers on action spaces and pieces and take an action. When the timer runs out, it may be moved to another location to take that action. There are no turns -; players may move their own timers any time after they have expired.",
"Action/Event": "On their turn, the player plays a card that shows Action Points and an Event. They must choose to either use the Action Points or perform the Event. If they choose to use Action Points, typically the Event may be performed by another player.",
"Advantage Token": "One player has a token that permits them to do a special Action, or modify an Action. Once used, the token passes to another player.",
"Alliances": "Players have formal relationships that may change over the course of the game.",
"Area Majority / Influence": "Multiple players may occupy a space and gain benefits based on their proportional presence in the space.",
"Area Movement": "The game board is divided into irregularly shaped areas to determine adjacency and movement. This can be contrasted to point to point movement (which may be isomorphic to area movement but does not have to be) and grid movement (which uses regular shapes). An example of point to point maps not being isomorphic to each other would be a map of Europe with air travel. France and Ireland share no borders and would not be adjacent on an area map but a point to point could easily contain an air route.",
"Area-Impulse": "Players subdivide turns into impulses alternating between players which repeat until both players pass (or in some cases a sunset die roll ends the impulses catching one or both players off guard). In those impulses a group of units is once activated or gets to act collectively before being marked spent. However instead of the activated units being grouped by a certain radius from a leader the units activated are in an area (and thus the need for the impulse system to have areas, not hexes). The areas exist to define scope of activation on an impulse (as well as restrict what can be done on that impulse with respect to attack and movement range). Thus each of a players groups of units each acts once by means of small alternating impulses instead of the traditional all my units then all your units. Finally, before the next turn of impulses, spent units are reset and regain the ability to act.",
"Auction/Bidding: Dexterity, Dutch, Dutch Priority, English, Fixed Placement, Multiple Lot, Once Around, Sealed Bid, Turn Order Until Pass, Auction Compensation, Closed Economy": "This mechanic requires you to place a bid, usually monetary, on items in an auction of goods in order to enhance your position in the game. These goods allow players future actions or improve a position. The auction consists of taking turns placing bids on a given item until one winner is established, allowing the winner to take control of the item being bid on. Usually there is a game rule that helps drop the price of the items being bid on if no players are interested in the item at its current price. In most games, once a winner for one item is done, if there are more items to be bid upon, another auction is held for those items. The process repeats until a game condition is met or items are exhausted in the auction phase of the game. This is similar to Worker Placement, but workers can be kicked off spots by bidding higher.",
"Automatic Resource Growth": "The automatic increase of a resource triggered by a particular, conditional, deterministic (not random) game state.",
"Betting and Bluffing": "Players commit a stake of currency or resources to purchase a chance of winning everyones stake, based on some random outcome like being dealt a superior set of cards or rolling a higher number. Players typically have partial information about the overall game state, and may “bluff,” by representing through their in-game actions that they hold a stronger position than they do. Conversely, players may “fold,” or quit the contest, and limit their losses to whatever they had already staked.",
"Bias": "Pieces automatically move in a certain direction, or it is easier to move in a certain direction, or some directional/environmental vector has game play effects.",
"Bids As Wagers": "Players bid that they can achieve some outcome in the ensuing gameplay phase. Scoring is based on whether and how well players achieve their bids. Examples would be Spades or Contract Bridge.",
"Bingo": "Items are selected at random, and each player needs to use the items for their own player boards.",
"Bribery": "Players offer bribes to other players to get them to perform specific actions.",
"Campaign / Battle Card Driven": "The Campaign/Battle Card Driven mechanic is a relatively recent development in wargames that focuses the players' actions on cards they have in their hand. Performing a single action uses a single card; cards will often be multi-purpose. Action/Event is a similar but more distinctive mechanic. Games where the cards are used to deploy specific units are considered to be using Command Cards. Games where cards are used to affect the outcome of battles are considered to be using Card Play Conflict Resolution.",
"Card Play Conflict Resolution": "Each player simultaneously or sequentially plays one or more cards. These modify the base outcome of a conflict and allow various special abilities to apply. This can function similarly to Area Majority / Influence, but with a discrete resolution or award of the conflict target, rather than by dynamic/shifting control of a fixed asset. It is also similar to Force Commitment, but that mechanic presupposes a more liquid resource where the quantity can be chosen.",
"Catch the Leader": "The game systems advantage players that are behind or disadvantage players that are ahead. If this advantage is limited to turn order, then Turn Order: Stat-Based should be tagged instead.",
"Chaining": "Pieces are stationary but are built out in chains. This can give a dynamic sense of motion to the game, even though the pieces do not move.",
"Chit-Pull System": "Used in wargames to address the problem of simulating simultaneous action on the battlefield and issues of Command and Control. In such a system the current player randomly draws a chit or counter identifying a group of units which may now be moved. Schemes include moving any units commanded by a particular leader, moving units of a particular quality or activating units not for movement but for fighting.",
"Closed Drafting": "Closed Drafting (or 'Card Drafting') is a means of distributing cards or other game elements to players through an ordered, closed selection process - typically select and pass.",
"Command Cards": "Players have a hand of cards that allows them to activate and perform actions with a subset of their units.",
"Commodity Speculation": "A subcategory of Investment in which players buy and sell commodities of various types and quantities as they change value throughout the game.",
"Communication Limits": "Games may limit players from communicating with one another openly. These restrictions can be absolute as they relate to certain specific pieces of information, or they may restrict certain types of communication, such as speaking.",
"Connections": "Players are trying to form connections between different points on the board. Essentially, this is a specialized kind of set collection in which the sets collected represent ties between nodes, often represented as routes between destinations.",
"Constrained Bidding": "This is a meta-mechanism that can modify other auction techniques. Players may not bid any number that they wish. They may only bid based on increments and/or combinations of certain resources.",
"Contracts": "Players fulfill Contracts to earn rewards. These take the form of special goals requiring coordination and planning beyond simply being first past the post. These can be either public, where all players compete to be the first to complete them, or private, where only the owning player may fulfill them.",
"Cooperative Game": "Players coordinate their actions to achieve a common win condition or conditions. Players all win or lose the game together.",
"Crayon Rail System": "The Crayon Rail System is a subcategory of Route/Network Building. Types of these games use crayon or other non-permanent methods of making connecting lines on a board, often eraseable. The most popular Crayon Rail games are part of the Empire Builder system.",
"Critical Hits and Failures": "Dice are rolled, and those exceeding a target number generate a success. Certain rolls (typically the highest and/or lowest on the die) generate additional success or extreme failure.",
"Cube Tower": "Actions are resolved by dropping cubes of various colors into a tower and seeing which emerge out the bottom. Cubes that do not emerge may come out in future actions, and impact those. This is in essence a particular form of a shared Deck, Bag, and Pool Building as implemented by a Components: Drop Tower.",
"Deck Construction": "As the first step, or prior to playing the game, players create the deck they will use.",
"Deck, Bag, and Pool Building": "Players play cards out of individual decks, seeking to acquire new cards and to play through their decks iteratively, improving them over time through card acquisition or card elimination.",
"Deduction": "Players are trying to determine the identity of hidden information based on clues.",
"Delayed Purchase": "Items that are purchased do not enter play right away but arrive on a future turn.",
"Dice Rolling": "Dice Rolling is a game mechanism that can be used for many things, randomness being the most obvious.",
"Die Icon Resolution": "The player rolls a number of custom dice to resolve an event or conflict. Results must match specific symbols for success.",
"Different Dice Movement": "Different Dice are used to move depending on unit or game state.",
"Drawing": "Players draw a picture and other players guess what the picture is intended to depict.",
"Elapsed Real Time Ending": "The game ends after a set amount of actual time has elapsed. During the game, individual players may have separate turns, or play may be simultaneous--in which all players play at the same time.",
"Enclosure": "In Area Enclosure games, players place or move pieces in order to surround areas contiguously with their pieces. The oldest and most famous Area Enclosure game is of course Go, but many newer examples also exist.",
"End Game Bonuses": "Players earn (or lose!) bonus Victory Points (VPs) at the end of the game based on meeting victory conditions.",
"Events": "Actions occur outside the control of players that cause an immediate effect, change the state of the game, or impact subsequent actions.",
"Finale Ending": "When the main game ends, a special mini-game is played to determine the victor.",
"Flicking": "A Physical Action needs to be performed by one or more players to determine the outcome of the action.",
"Follow": "One player selects an Action. Other players may then perform that Action, or a modified version of it. This is closely related to Action Drafting (ACT-02) and Role Selection (TRN-10), and is often implemented alongside those systems.",
"Force Commitment": "The players select how many of their forces they will commit to the battle to different categories. This can be simultaneous (hidden) or incrementally, prior to resolution. Frequently players must lose all forces that they commit. Arguably this is a form of auction/bidding, but distinctive when applied to war or area control games for multiple/parallel contests.",
"Grid Coverage": "A family of mechanisms in which players cover a grid or fill a space using a variety of shapes, such as Components: Polyominoes. This includes the coverage of areas defined (by shape or color) on the board itself with pieces or tiles.",
"Grid Movement": "Grid Movement occurs when pawns move on the grid in many directions. The grid may be square (like in Chess) or hexagonal (like in the classic wargames).",
"Hand Management": "Hand management games are games with cards in them that reward players for playing the cards in certain sequences or groups. The optimal sequence/grouping may vary, depending on board position, cards held and cards played by opponents. Managing your hand means gaining the most value out of available cards under given circumstances. Cards often have multiple uses in the game, further obfuscating an optimal sequence.",
"Hexagon Grid": "Pieces are placed on a board tessellated with hexagons, which is used for adjacency and/or movement.",
"Hidden Movement": "Movement occurs that is not visible to all players.",
"Hidden Roles": "In games with hidden roles, one or more players are assigned differing roles that are not publicly revealed.",
"Hidden Victory Points": "The number of Victory Points held by each player is private information.",
"Highest-Lowest Scoring": "Each player's score is equal to the lowest value of several categories. Whoever has the highest lowest value is the winner.",
"Hot Potato": "A single item is bad for players to have, and players strive to pass it to other players or avoid it so they are not holding it at game end or some other defined time.",
"I Cut, You Choose": "A method of drafting where one player divides a set of resources into different groups; other players have first choice of which group to select.",
"Impulse Movement": "A turn is broken up into a series of small Impulses. Depending on their speed, units will be able to move in specific Impulses.",
"Income": "Players gain resources at defined times.",
"Increase Value of Unchosen Resources": "If players do not select certain Actions or Resources, then they increase in value.",
"Induction": "Players are attempting to determine the rules governing a situation. In a typical induction games, a game master creates a hidden rule. Players then create patterns and are advised by the game master whether they match the rule or not.",
"Interrupts": "This is a meta-mechanic for Turn Order. Players may take an action that interrupts the normal turn flow.",
"Investment": "Players purchase an interest in a game entity, in order to generate a monetary or VP benefit, the ultimate value of which is determined over the course of the game. This differs from Ownership in that Investment does not grant special actions or other game effect privileges based on the investment.",
"Kill Steal": "Players contribute towards completing a task, but only the player who finally completes it gets a particular benefit or bonus reward (even if others share in the base level benefit).",
"King of the Hill": "Games with a king of the hill mechanism reward players with points or other advantages for occupying a special position on the board. How long can you hold your ground?",
"Ladder Climbing": "Players play one card, or a set of related cards. Subsequently, players must play cards of an equal or higher value of the same set already played. The last player to successfully play wins the right to start a new round of Climbing.",
"Layering": "Components are placed above other components, partially covering the ones below, or overlapping in various ways. Only the topmost visible icons/areas are active.",
"Legacy Game": "A multi-session game in which permanent and irreversible changes to the game state carry over to future plays. Components may be written on with permanent ink, torn apart, covered with a sticker, etc.",
"Line Drawing": "Games using the line drawing mechanic involve the drawing of lines in one way or another. Lines may be used to connect objects as in Sprouts, to isolate objects, or to create areas as in the classic Dots and Dashes, also known as Square-it.",
"Line of Sight": "Units may only see certain areas.",
"Loans": "Players may take a Loan from the bank to get more money.",
"Lose a Turn": "This is a meta-mechanism that can be applied to a variety of turn structures. A player who “Loses a Turn” must skip their next opportunity for a turn, and will go to the next round, or the next time their turn arises.",
"Mancala": "In a typical Mancala mechanism, players pick up the tokens in one space, and then place them one-by-one in spaces in a specific direction around a circle, with the last space placed in having special significance.",
"Map Addition": "The map is added to as it is explored. Examples include Mississippi Queen and Eclipse: New Dawn for the Galaxy.",
"Map Deformation": "The map is modified during the course of the game through rotation or shifts.",
"Map Reduction": "Over the course of the game, the map shrinks.",
"Market": "Players may buy from or sell resources to Markets, where prices and quantities can vary. This is often a primary feature of Economic games, but sometimes only a secondary mechanism.",
"Matching": "Players must make their next play by matching a feature on the previous play. This is frequently used in shedding games like UNO or Crazy Eights, where number or color/suit need to be matched.",
"Measurement Movement": "Pieces may be moved up to a certain distance, measured by a ruler.",
"Melding and Splaying": "A set of cards in a specific relationship to one another that allows them to be played to a table or scored as a meld. When laying these cards down, the way the cards splay, or overlap one another, may sometimes reveal or conceal certain abilities or attributes.",
"Memory": "Hidden, trackable information whose tracking gives players an advantage.",
"Minimap Resolution": "When a conflict is initiated, pieces are moved to a separate board for resolution.",
"Modular Board": "Play occurs upon a modular board that is composed of multiple pieces, often tiles or cards.",
"Move Through Deck": "Players Move Through a Deck of cards. Typically the goal is to reach the bottom (One Deck Dungeon), find and defeat a boss (Chainsaw Warrior), or simply know when to quit (Incan Gold).",
"Movement Points": "A piece is given a number of points to spend on movement. This is common in a variety of games, but particularly war games, where spaces can cost different amounts depending on the terrain. This is distinct from Action Points in that it is a property of the particular piece or terrain, rather than player options (which may include movement).",
"Movement Template": "A defined Movement Template is used to determine where a piece moves to.",
"Moving Multiple Units": "Actions may Move one or Multiple Units.",
"Multi-Use Cards": "Multiple actions are shown on a card, but only one can be used.",
"Multiple Maps": "The game takes place on Multiple Maps which are connected at defined points.",
"Narrative Choice / Paragraph": "Multiple action options are presented to the players via a narrative format.",
"Negotiation": "Players make agreements about coordinating action, beyond simply Trading.",
"Neighbor Scope": "Actions, resources, or resolution are shared between neighbors.",
"Network and Route Building": "The game involves the development of connected routes and nodes, often represented as routes between destinations. This is differentiated from Connections in that it provides some in-game effect beyond merely scoring, such as the ability to trigger actions, or requiring maintenance costs.",
"Once-Per-Game Abilities": "Players have a special ability that they can use one time per game.",
"Open Drafting": "Open Drafting is used in games in which players pick cards (or tiles, resources, dice, etc) from a common pool, to gain some advantage (immediate or longterm) or to assemble collections that are used to meet objectives within the game.",
"Order Counters": "Players place Order Markers into specific regions (or zone, or hexagon, or square) of the game board, indicating what they want to do in that particular region of the game board. After all markers are placed, they are executed in sequence.",
"Ordering": "The objective of the game is to rearrange a group of game elements from a disordered to an ordered state.",
"Ownership": "Players own entities, and perform actions for those entities, or collect benefits if others use them.",
"Paper-and-Pencil": "The game is developed using paper and pen to mark and save responses or attributes that, at the end of the game, are used to score points and determine the winner.",
"Passed Action Token": "Players possess one or more Action Tokens. Those who have an Action Token may take a turn, then they pass the token clockwise, allowing the next player to perform an action. Actions are performed in real time; - there is no pausing and structure within the turn.",
"Pattern Building": "Players must configure game components in sophisticated patterns in order to score or trigger actions, as would be typical for games in the Puzzle category.",
"Pattern Movement": "Pieces move in a specific pattern relative to the board grid.",
"Pattern Recognition": "Players must recognize a known or emergent pattern created by the game components to gain objectives or win the game. This could for instance involve markers, typically with a color or symbol, placed to certain locations on a board, or relative to the other markers, forming an abstract or meaningful pattern, requiring deductive reasoning by players to determine its significance.",
"Physical Removal": "Pieces are removed from a structure, and play is affected by things that fall (as in Ker Plunk), or a complete collapse of the structure (as in Jenga).",
"Pick-up and Deliver": "This mechanism usually requires players to pick up an item or good at one location on the playing board and bring it to another location on the playing board. Initial placement of the item can be either predetermined or random. The delivery of the good usually gives the player money to do more actions with. In most cases, there is a game rule or another mechanic that determines where the item needs to go.",
"Pieces as Map": "The Pieces themselves compose the Map.",
"Player Elimination": "A player can be removed from the game, no longer participating.",
"Player Judge": "One player, the judge, decides the outcome of the Action.",
"Point to Point Movement": "On a board of a game with point-to-point movement, there are certain spots that can be occupied by markers or figurines, e. g. cities on a map. These points are connected by lines, and movement can only happen along these lines. It is not enough that two points are next to or close to each other; if there is no connecting line between them, a player cannot move his or her piece from one to the other.",
"Predictive Bid": "Players make a prediction about what they will do in a future part of the game, and may score points based on how well they match the prediction.",
"Prisoner's Dilemma": "Each player has a choice between Cooperating or Defecting. Total payoff is maximized if both players Cooperate, but if one Defects and the other Cooperates, the Defector will score more individual points.",
"Programmed Movement": "Players simultaneously program their movement, and then reveal and execute it. This mechanism tends to promote chaos in a game, and benefits those with good spatial relations.",
"Push Your Luck": "Players must decide between settling for existing gains, or risking them all for further rewards, in a game with some amount output randomness or luck. Push-Your-Luck is also known as press-your-luck.",
"Questions and Answers": "Players ask and answer questions in a manner constrained by rules.",
"Race": "Games where the first player to achieve a key objective wins the game. Typically this is expressed as the winner being the first player to reach the end of a track, but any type of fixed goal also qualifies as a Race mechanism. CATAN is an example, where players race to reach 10 points. The Quest for El Dorado is an example, where players race to reach a goal on a map.",
"Random Production": "Resources are generated from a random process and distributed to qualifying players.",
"Ratio / Combat Results Table": "In many Board Wargames to resolve a combat between units, the Attacker and Defender total the strength of the units involved in that combat. This is then expressed as a Ratio (Attacker versus Defender) which is used to index into a Combat Results Table (CRT). A dice roll then determines the final result of the combat.",
"Re-rolling and Locking": "Dice may be rerolled, or may be locked, preventing rerolling.",
"Real-Time": "There are no turns. Players play as quickly as possible, subject to certain constraints, until the game or phase is completed.",
"Relative Movement": "The precise location of units is not tracked. Only their Relative Position is important.",
"Resource Queue": "Resources are in an ordered queue, and can only be pulled from one end, or rarely, both ends, but not the middle.",
"Resource to Move": "Players expend a Resource to Move.",
"Rock-Paper-Scissors": "There are three possible options, and they are cyclically superior (A beats B, B beats C, and C beats A).",
"Role Playing": "Some board games incorporate elements of role playing. It can be that players control a character that improves over time. It can also be a game that encourages or inspires Storytelling. This mechanic can be viewed as an extension of Variable Player Powers.",
"Roles with Asymmetric Information": "One or more players are secretly assigned roles at the start of the game which have different win conditions, and receive different starting information about the game state.",
"Roll / Spin and Move": "Roll / Spin and move games are games where players roll dice or spin spinners and move playing pieces in accordance with the roll.",
"Rondel": "The available Actions are represented as pie wedges in a circle. Each player has one or more tokens on Rondel’s wedges. On their turn, they may move their token around the Rondel and perform the Action indicated by the wedge where they stop. It is typically more costly to move further around the Rondel.",
"Scenario / Mission / Campaign Game": "A game system that can be applied to a variety of different maps, starting resources and positions, and even different win and loss conditions. These variable conditions can be assembled into a broader narrative or campaign, or they can be entirely disconnected from one another.",
"Score-and-Reset Game": "A game in which players play until reaching a stopping condition, then record scores, reset the game, and play one or more additional rounds. The game concludes after some number of rounds, and the cumulative score is calculated to determine a winner.",
"Secret Unit Deployment": "In Secret Unit Deployment games, some (or all) pieces enter the board in secret, and only the player controlling certain playing pieces has perfect information about the nature (or even the whereabouts) of those pieces. Other players will not know where those pieces are located on the board, or they may know where pieces are, but not know the details (such as strength or type) of these pieces. This mechanic is often used in wargames to simulate fog of war.",
"Selection Order Bid": "Selection Order Bid is a form of multiple-lot auction in which players are not directly bidding on the lots themselves, but the order in which they'll draft the lots. As the bid increases, players may pass and accept a later place in the order. In some cases, players must pay their entire current bid (an all-pay mechanism), and in others they may recover some of their bid.",
"Semi-Cooperative Game": "A game in which players are cooperating and competing with each other throughout the game, while trying to complete a common objective.",
"Set Collection": "The value of items is dependent on being part of a set; for example, scoring according to groups of a certain quantity or variety.",
"Simulation": "Simulation is not a specific mechanism, but more of a property of a game and its mechanisms. Simulation games attempt to create a realistic model of actual events or situations. Often, but not always, this means that some aspects of the situation is worked out in detail.",
"Simultaneous Action Selection": "Players plan their turn secretly and simultaneously. Then, they reveal their plans at the same time.",
"Singing": "Games that use a Singing mechanic require players to hum or sing familiar songs in order to fulfill certain game requirements.",
"Single Loser Game": "A game which concludes when a single player loses.",
"Slide/Push": "Players push or slide a token, and other tokens ahead of it are also pushed.",
"Solo / Solitaire Game": "Games that are intended for play by a single player, or that have a game mode intended for play by a single player.",
"Speed Matching": "A pattern is revealed, typically through a card flip, and players try to be the first to find a match with other game elements on the table, or see if a match exists.",
"Spelling": "Players arrange cards, tiles, or other components that represent an individual letter or small group of letters to create words. Examples include Scrabble and Boggle.", 
"Square Grid": "Pieces are placed on a board tessellated with squares, which is used for adjacency and/or movement",
"Stacking and Balancing": "Players must physically stack and balance pieces.",
"Stat Check Resolution": "There is a target number required to succeed at some test. A random number is generated (by card draw, die roll, etc.), which is compared to the target. If it meets or exceeds the target, the action succeeds.",
"Static Capture": "Pieces are captured when another piece occupies or passes over their space.",
"Stock Holding": "Stock Holding is a subcategory of Investment, in which players may buy and sell (or retain) defined interests in a shared asset, such as a company, commodity or nation. This will often grant certain privileges of Ownership.",
"Storytelling": "In storytelling games, players are provided with conceptual, written, or pictorial stimuli which must be incorporated into a story of the players' creation. Once Upon a Time uses a selection of words while Rory's Story Cubes include ambiguous symbols. Some games like Snake Oil and Big Idea prompt players to pitch a product, which frequently takes the form of a brief story or vignette.",
"Sudden Death Ending": "There are two distinct types of Sudden Death Endings: 1. In the case of a tie at the end of regular play, ‘sudden death’ is triggered where play continues until the game ends immediately after a player achieves a certain condition (like scoring a point). 2. Special victory conditions which when met trigger an early and immediate end to game play (perhaps completing the current round), prior to the normal ending conditions for the game.",
"Tags": "Game objects, typically cards, have icons or other identifiers that identify them as belonging to specific categories. These tags may trigger special effects and/or have values and meaning that can vary, even within the scope of a single play. Tags are additional parameters on top of the base meaning of the game element, so tags represent a means of coupling the game element with more mechanisms and systems. Tags are also bookmarks that can reference a variable set of possible rules that are encoded elsewhere, so tags are also a means to modularize, or uncouple, game triggers and game effects.",
"Take That": "Competitive maneuvers that directly target one opponent's progress toward victory, but do not directly eliminate any characters or components representing the opponent. Such mechanics include stealing, nullifying, or force-discarding of one opponent's resources, actions, or abilities. A take-that maneuver often results in a dramatic change in the players' position of power over a relatively short period of time.",
"Targeted Clues": "A player gives clues that they want some, but not all, players to guess.",
"Team-Based Game": "In team-based games, teams of players compete with one another to obtain victory. There are a variety of possible team structures, including symmetrical teams like 2v2 and 3v3, multiple sides like 2v2v2, and even One vs. All.",
"Tech Trees / Tech Tracks": "During the course of the game, new Actions become available to specific players, or existing Actions are improved. These are often themed as Technologies, but do not need to be.",
"Three Dimensional Movement": "Position and movement of pieces is in three dimensions. This can either be represented by a multi-level play surface, or some token or indicator of the height above or below a two-dimensional play surface.",
"Tile Placement": "Tile Placement games feature placing a piece to score VPs or trigger actions, often based on adjacent pieces or pieces in the same group/cluster, or keying off non-spatial properties like color, feature completionc, cluster size etc.",
"Track Movement": "Pieces are moved along a linear track (not necessarily straight - it may turn, curve or loop).",
"Trading": "Players may Trade assets directly with each other, rather than via a Market.",
"Traitor Game": "A traitor game can be seen as a kind of team game, or as a cooperative game with a betrayal mechanism. The traitors typically win by triggering a failure condition for the players. For this mechanism, a traitor game is characterized by traitors that begin the game with hidden identities, or are assigned them during the game.",
"Trick-taking": "Players play cards from their hand to the table in a series of rounds, or “tricks” which are each evaluated separately to determine a winner and to apply other potential effects.",
"Tug of War": "A marker is moved up and back on a track towards or away from a neutral position.",
"Variable Phase Order": "Variable Phase Order implies that turns may not be played the same way as before and / or after.",
"Variable Player Powers": "Variable Player Powers is a mechanic that grants different abilities and/or paths to victory to the players.",
"Variable Set-up": "The starting game state varies from game to game, through changes to shared game components like the map, and/or changes to starting player set-ups, resources, objectives, etc.",
"Victory Points as a Resource": "Victory Points (VPs) may be spent as a currency to impact the game state.",
"Voting": "Players vote on whether a proposed action will occur or not.",
"Worker Placement": "A stylized form of Action Drafting, players place tokens (typically the classic person-shaped meeple) to trigger an action from a set of actions available to all players, generally one-at-a-time and in turn order. Some games achieve the same effect in reverse: the turn begins with action spaces filled by markers, which are claimed by players for some cost. Each player usually has a limited number of tokens with which to participate in the process, although these may increase as the game progresses.",
"Worker Placement with Dice Workers": "Workers are represented by dice whose pip values impact play.",
"Worker Placement, Different Worker Types": "Workers can differ in abilities, or can be upgraded and downgraded, or are valid for placement in different areas and buildings.",
"Zone of Control": "Spaces adjacent to a unit impact the ability of opposing units to move or attack."}

export const turnOrderOptions = ["Auction",
"Claim Action",
"Pass Order",
"Progressive",
"Random",
"Role Order",
"Stat-Based",
"Time Track"]

export function replaceMechanic(originalIndex, chosenMechanics) {
    var iterations = 0
    while(iterations < 1000) {
        iterations++;
        var index = Math.floor(Math.random() * Object.keys(mechanics).length)
        var key = Object.keys(mechanics)[index]
        var alreadyHas = false
        for (let index = 0; index < chosenMechanics.length; index++) {
            const existingChoice = chosenMechanics[index];
            if (key === existingChoice) {
                alreadyHas = true
                break
            }
        }
        if (!alreadyHas) {
            chosenMechanics[originalIndex] = key
            return chosenMechanics
        }
    }
    return chosenMechanics
}

export function choose(count, maxIterations = 1000) {
    if (Object.keys(mechanics).length <= count) {
        return mechanics
    }
    var iterations = 0
    var chosenMechanics = []
    while(chosenMechanics.length < count) {
        iterations++;
        if (iterations >= maxIterations) {
            console.log("Ran out of iterations")
            return chosenMechanics
        }
        var index = Math.floor(Math.random() * Object.keys(mechanics).length)
        var key = Object.keys(mechanics)[index]
        // console.log(key, mechanics[key])
        var alreadyHas = false
        for (let index = 0; index < chosenMechanics.length; index++) {
            const existingChoice = chosenMechanics[index];
            if (key === existingChoice) {
                alreadyHas = true
                break
            }
        }
        if (!alreadyHas) {
            chosenMechanics.push(key)
        }
    }
    return chosenMechanics
}