import './App.css';
import React from "react";
import {choose, replaceMechanic, turnOrderOptions, mechanics} from "./mechanics"
export default class App extends React.Component {
    state = {
        chosenMechanics: [],
        turnOrder: ""
    }
    componentDidMount = () => {
        var chosenMechanics = choose(3, mechanics, 1000, this.state.chosenMechanics)
        var turnOrder = turnOrderOptions[Math.floor(Math.random() * turnOrderOptions.length)]
        this.setState({chosenMechanics, turnOrder})
    }
    rerollAll = () => {
        var chosenMechanics = choose(3, mechanics, 1000, this.state.chosenMechanics)
        var turnOrder = turnOrderOptions[Math.floor(Math.random() * turnOrderOptions.length)]
        this.setState({chosenMechanics, turnOrder})
    }
    cycleOption = (index) => {
        var chosenMechanics = replaceMechanic(index, this.state.chosenMechanics)
        this.setState({chosenMechanics})
    }
    cycleTurnOrder = () => {
        var turnOrder = turnOrderOptions[Math.floor(Math.random() * turnOrderOptions.length)]
        this.setState({turnOrder})
    }
    getMechanics = (i) => {
        return Object.keys(mechanics).map((mechanic, index) => {
            if (index % 3 != i) {
                return <></>
            }
            return <div className="mechanic-blurb">
                <p>{mechanic}</p>
                <p>{mechanics[mechanic]}</p>
            </div>
        })
    }
    render = () => {
        var shuffleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
        </svg>
        var copyIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
        </svg>
        return <div className="App container-fluid">
            <img src="/icon.svg" className='main-icon' alt="Website icon of an eye on Board Game Geek Logo"/>
            <button className='btn btn-primary reroll-all-button' onClick={() => this.rerollAll()}>Reroll All {shuffleIcon}</button>
            <div className='mechanic-buttons'>
                {this.state.chosenMechanics.map((mechanic, index) => {
                    var description = mechanics[mechanic]
                    return <div key={mechanic}  className='mechanic-container'>
                        <div>
                            <button className='btn btn-primary mechanic-button' onClick={() => this.cycleOption(index)}>{mechanic} {shuffleIcon}</button>
                        </div>
                        <div> 
                            <p className='mechanic-explanation'>{description}</p>
                        </div>
                    </div>
                })}
            </div>
                
            <button className='btn btn-primary turn-order-button' onClick={() => this.cycleTurnOrder()}>{this.state.turnOrder} Turn Order {shuffleIcon}</button>
            <p>This app is based on the Board Game Geek <a href="https://boardgamegeek.com/browse/boardgamemechanic">Board Game Mechanics List</a>.</p>
            
            
            <div className="row mechanics-columns">
                <div className='col'>
                    {this.getMechanics(0)}
                </div>
                <div className='col'>
                    {this.getMechanics(1)}
                    
                </div>
                <div className='col'>
                    {this.getMechanics(2)}
                </div>
            </div>
        </div>
    }
}
